<template>
  <div class="OS">
    <OtherBanner :banner-list="bannerListPc" :banner-list-mobile="bannerListMobile" type-view="none" />
    <!-- pc -->
    <div class="OS-pc">
      <div class="one-container-pc">
        <span class="top-left-corner icon-ellipsis" />
        <div class="center-top-box">
          <p class="title">
            优智多智慧教育生态
          </p>
          <p class="sub-title">
            让数字技术为教育赋能，更好服务育人本质
          </p>
          <div class="one-remark">
            <span class="left-mark icon-size" />
            <div class="info">
              按照“应用为王、服务至上、简洁高效、安全运行”的原则，把智能终端、趣味教学平台、新型多功能教室等诸多典型产品、应用、资源内容集成，打造优智多智慧教育生态，让数字技术为教育赋能，更好服务育人本质。
            </div>
            <span class="right-mark icon-size" />
          </div>
        </div>
        <div class="center-context-box">
          <span class="img1 bg-icon img" />
          <div class="center-context-info">
            <p class="type">
              交互式多功能教室
            </p>
            <p class="sub-type">
              多向互动 协作探究
            </p>
            <div class="button" @click="$router.push({path:'/room/multiClassroom'})">
              立即体验 ⇀
            </div>
          </div>
        </div>
        <span class="bottom-right-corner icon-ellipsis" />
      </div>
      <div class="two-container-pc">
        <div class="center-context-box">
          <div class="center-context-info">
            <p class="type">
              优智多趣味教学平台
            </p>
            <p class="sub-type">
              有趣的教 有趣的学
            </p>
            <div class="button" @click="$router.push({path:'/educ/educationPlatform'})">
              立即体验 ⇀
            </div>
          </div>
          <span class="img2 bg-icon img" />
        </div>
      </div>
      <div class="three-container-pc">
        <span class="top-left-corner icon-ellipsis" />
        <div class="center-context-box">
          <span class="img3 bg-icon img" />
          <div class="center-context-info">
            <p class="type">
              课桌先生
            </p>
            <p class="sub-type">
              智趣新陪伴 学习好伙伴
            </p>
            <div class="button" @click="$router.push({path:'/desk/home'})">
              立即体验 ⇀
            </div>
          </div>
        </div>
        <span class="bottom-right-corner icon-ellipsis" />
      </div>
    </div>
    <!-- mobile -->
    <div class="OS-mobile">
      <div class="mobile-one">
        <p class="title">
          优智多智慧教育生态
        </p>
        <p class="sub-title">
          让数字技术为教育赋能，更好服务育人本质
        </p>
        <div class="desc">
          <span class="left-mark icon-size bg-icon" />
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;按照“应用为王、服务至上、简洁高效、安全运行”的原则，把智能终端、趣味教学平台、新型多功能教室等诸多典型产品、应用、资源内容集成，打造优智多智慧教育生态，让数字技术为教育赋能，更好服务育人本质。
          </p>
          <span class="right-mark icon-size bg-icon" />
        </div>
      </div>
      <div class="mobile-two-box">
        <div v-for="(item,index) in viewData" :key="index" class="mobile-item">
          <img :src="item.imgUrl" class="imgBox">
          <p class="title">
            {{ item.title }}
          </p>
          <p class="sub-title">
            {{ item.subTitle }}
          </p>
          <div class="btn" @click="toPage(item.index)">
            立即体验
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OtherBanner from '@/components/baseComon/otherBanner.vue';
export default {
  name: 'OS',
  components: { OtherBanner },
  data() {
    return {
      bannerListPc: [
        {
          imgUrl: require('../../assets/images/os/os-banner1.png')
        }
      ],
      bannerListMobile: [
        {
          imgUrl: require('../../assets/images/moblie/icon-osHome-banner.png')
        }
      ],
      viewData: [
        {
          imgUrl: require('../../assets/images/room/icon-room1.jpg'),
          title: '交互式多功能教室',
          subTitle: '多向互动 协作探究',
          index: 1,
          linkUrl: '#/room/multiClassroom'
        },
        {
          imgUrl: require('../../assets/images/os/osHome-img2.png'),
          title: '优智多趣味教学平台',
          subTitle: '有趣的教 有趣的学',
          index: 2,
          linkUrl: '#/educ/educationPlatform'
        },
        {
          imgUrl: require('../../assets/images/os/osHome-img4.png'),
          title: '课桌先生',
          subTitle: '智趣新陪伴 学习好伙伴',
          index: 3,
          linkUrl: '#/desk/home'
        }
      ]
    };
  },
  methods: {
    toPage(index) {
      if (index === 1) {
        this.$router.push({ path: '/room/multiClassroom' });
      } else if (index === 2) {
        this.$router.push({ path: '/educ/educationPlatform' });
      } else {
        this.$router.push({ path: '/desk/home' });
      }
    }
  }
};
</script>
<style lang="scss" scope>
@media screen and (min-width: 1200px) {
  .OS {
    background: #fff;
    .OS-mobile{
      display: none;
    }
    .banner{
      width: 100%;
      max-width: 1920px;
      height: auto;
      min-height: 600px;
      margin: 0 auto;
      .banner-img{
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
    .el-carousel__container{
        width: 100%;
        height: auto;
        min-height: 600px;
    }
    .center-context-box{
      max-width: 1200px;
      display: flex;
      align-items: center;
      .img{
        display: block;
        width: 585px;
        height: 320px;
        &:hover {
          transform: scale(1.1);
        }
      }
      .center-context-info{
        .type{
          font-size: 36px;
          font-weight: 400;
          color: #333333;
        }
        .sub-type{
          font-size: 22px;
          font-weight: 400;
          color: #999999;
          margin: 20px 0 30px;
        }
        .button{
          width: 143px;
          padding: 12px 0;
          background: #FD9C02;
          border-radius: 90px 90px 90px 90px;
          font-size: 18px;
          font-weight: 400;
          color: #FFFFFF;
          text-align: center;
          cursor: pointer;
          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
    .one-container-pc{
      position: relative;
      .top-left-corner{
        position: absolute;
        left: 0;
        top: -60px;
      }
      .bottom-right-corner{
        position: absolute;
        right: 0;
        bottom: 0;
      }
      .center-top-box{
        max-width: 1200px;
        margin: 0 auto;
        text-align: center;
        .title{
          font-size: 44px;
          font-weight: bold;
          color: #333333;
          margin: 60px 0 10px;
        }
        .sub-title{
          font-size: 24px;
          font-weight: 400;
          color: #FD9C02;
          line-height: 28px;
        }
        .one-remark{
          margin-top: 25px;
          background: #F5F7FA;
          border-radius: 10px 10px 10px 10px;
          opacity: 1;
          padding: 40px 50px;
          text-align: left;
          position: relative;
          .icon-size{
            display: block;
            width: 40px;
            height: 35px;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            &.left-mark{
              top: -10px;
              left: 35px;
            }
            &.right-mark{
              bottom: -10px;
              right: 30px;
            }
          }
          .info{
            font-size: 18px;
            font-weight: 400;
            color: #666666;
            line-height: 34px;
          }
        }
      }
      .center-context-box{
        padding-bottom: 90px;
        margin: 90px auto 0;
        .img1{
          background-image: url('../../assets/images/os/osHome-img1.png');
        }
        .center-context-info{
          margin-left: 80px;
        }
      }
    }
    .two-container-pc{
      width: 100%;
      // height: 500px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-image: url('../../assets/images/os/osHome-img3.png');
      .center-context-box{
        margin: 0 auto;
        padding: 90px 0;
        justify-content: flex-end;
        .center-context-info{
          margin-right: 210px;
        }
        .img2{
          background-image: url('../../assets/images/os/osHome-img2.png');
        }
      }
    }
    .three-container-pc{
      position: relative;
      .top-left-corner{
        position: absolute;
        left: 0;
        top: 0px;
      }
      .bottom-right-corner{
        position: absolute;
        right: 0;
        bottom: 0;
      }
      .center-context-box{
        margin: 0 auto;
        padding: 90px 0;
        .center-context-info{
          margin-left: 80px;
        }
        .img3{
          background-image: url('../../assets/images/os/osHome-img4.png');
        }
      }
    }
  }
}
@media screen and (min-width:992px) and (max-width: 1200px){
  .mobile-two-box{
    .mobile-item{
      .imgBox {
        width: 100%;
        height: auto !important;
      }
    }
  }
}
@media screen and (max-width: 1200px) {
.OS-pc{
  display: none;
}
.OS{
  .mobile-one{
    text-align: center;
    margin-top: 25px;
    .title{
      font-size: 23px;
      font-weight: bold;
      color: #333333;
    }
    .sub-title{
      font-size: 13px;
      font-weight: 400;
      color: #FD9C02;
      margin: 5px 0 30px;
    }
    .desc{
      background: #fff;
      border-radius: 10px;
      font-size: 13px;
      font-weight: 400;
      color: #666666;
      margin: 0 15px;
      padding: 15px;
      line-height: 22px;
      position: relative;
      text-align: left;
      .icon-size{
        width: 20px;
        height: 17px;
        position: absolute;
        &.left-mark{
          top: -10px;
          left: 35px;
        }
        &.right-mark{
          bottom: -10px;
          right: 30px;
        }
      }
    }
  }
  .mobile-two-box{
    padding: 15px;
    .mobile-item{
      background: #fff;
      padding: 20px;
      margin-top: 20px;
      .imgBox{
        width: 100%;
        height: 167px;
      }
      .title{
        font-size: 19px;
        font-weight: bold;
        color: #333333;
        margin-top: 20px;
      }
      .sub-title{
        font-size: 13px;
        font-weight: 400;
        color: #999999;
        margin: 6px 0 18px;
      }
      .btn{
        width: 100%;
        background: #FD9C02;
        border-radius: 6px;
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
        padding: 15px 0;
        // height: 52px;
        // line-height: 52px;
        text-align: center;
      }
    }
  }
}

}
</style>
